import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroImage"

const PrivacyPolicy = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      privacy: file(relativePath: { eq: "privacy.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
          original {
            height
            src
            width
          }
        }
      }
    }
  `)

  const description =
    "司法書士法人みつわ合同事務所は、お客様の個人情報を安全かつ厳格に取り扱うために、「個人情報保護方針」を制定し、全所員への指導、教育の徹底を実践してまいります。"

  return (
    <Layout>
      <SEO
        description={description}
        title="Privacy Policy"
        pagepath={location.pathname}
        pageimg={data.privacy.childImageSharp.original.src}
        pageimgw={data.privacy.childImageSharp.original.width}
        pageimgh={data.privacy.childImageSharp.original.height}
      />
      <div className="main__container">
        <HeroImage
          name={"privacy"}
          fluid={data.privacy.childImageSharp.fluid}
          title={"司法書士法人みつわ合同事務所"}
          subTitle={"個人情報保護方針"}
        />
        <section className="privacy">
          <div className="privacy__container">
            <h1 className="privacy__title">個人情報保護方針</h1>
            <div className="privacy__desc">
              <p>
                司法書士法人みつわ合同事務所（以下「弊所」といいます。）は、弊所の提供するサービス（以下「本サービス」といいます。）における、お客様についての個人情報を含む利用者情報の取扱いについて、以下のとおり個人情報保護方針（以下「本ポリシー」といいます。）を定めます。
              </p>
              <div className="privacy__about">
                <h2>個人情報の取扱いについて</h2>
                <div className="privacy__about__item">
                  <h3>１．収集する利用者情報及び収集方法</h3>
                  <p>
                    本ポリシーにおいて、「利用者情報」とは、お客様の識別に係る情報、通信サービス上の行動履歴、その他お客様またはお客様の端末に関連して生成または蓄積された情報であって、本ポリシーに基づき弊所が収集するものを意味するものとします。<br/>
                    本サービスにおいて弊所が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
                  </p>
                  <h4>（１）お客様からご提供いただく情報</h4>
                  <p>本サービスを利用するために、または本サービスの利用を通じてお客様からご提供いただく情報は以下のとおりです。</p>
                  <ul>
                    <li>氏名、生年月日、職業等のプロフィールに関する情報</li>
                    <li>メールアドレス、電話番号、住所等連絡先に関する情報</li>
                    <li>銀行口座情報及び犯罪による収益移転防止に関する法律に基づく本人確認情報</li>
                    <li>入力フォームその他弊所が定める方法を通じてお客様が入力または送信する情報</li>
                    <li>お客様から依頼を受ける司法書士業務の具体的な内容</li>
                    <li>その他法令により司法書士が保管を義務付けられている情報</li>
                  </ul>
                  <h4>（２）お客様が本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報</h4>
                  <p>お客様が、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。</p>
                  <ul>
                    <li>当該外部サービスでお客様が利用するＩＤ</li>
                    <li>その他当該外部サービスのプライバシー設定によりお客様が連携先に開示を認めた情報</li>
                  </ul>
                  <h4>（３）お客様が本サービスを利用するにあたって、弊所が収集する情報</h4>
                  <p>弊所は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報を含みます。</p>
                  <ul>
                    <li>リファラ（リンク元の情報など、弊所ページへ流入経路）</li>
                    <li>IPアドレス</li>
                    <li>サーバーアクセスログに関する情報</li>
                    <li>Cookie、ADID、IDFAその他識別子（サイト訪問履歴や、ウェブ広告履歴など）</li>
                  </ul>
                  <h4>（４）お客様が本サービスを利用するにあたって、弊所がお客様の個別同意に基づいて収集する情報</h4>
                  <p>弊所は、お客様が３項(1)に定める方法により個別に同意した場合、弊所は以下の情報を ご利用中の端末から収集します。</p>
                  <ul>
                    <li>位置情報</li>
                  </ul>
                </div>
                <div className="privacy__about__item">
                  <h3>２．利用目的</h3>
                  <p>
                    本サービスのサービス提供に関わる利用者情報の具体的な利用目的は以下のとおりです。
                  </p>
                  <h4>（１）依頼内容の確定及び依頼内容の遂行のため</h4>
                  <h4>（２）本サービスに関する登録の受付、本人確認、お客様認証、お客様設定の記録、司法書士費用の決済、精算等本サービスの提供、維持、保護及び改善のため</h4>
                  <h4>（３）お客様のトラフィック測定及び行動測定のため</h4>
                  <h4>（４）広告の配信、表示及び効果測定のため</h4>
                  <h4>（５）本サービスに関するご案内、お問い合わせ等の対応のため</h4>
                  <h4>（６）法令改正等お役立ち情報の配信</h4>
                  <h4>（７）本サービスに関する弊所の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため</h4>
                  <h4>（８）本サービスに関する規約等の変更などを通知するため</h4>
                </div>
                <div className="privacy__about__item">
                  <h3>３．通知・公表または同意取得の方法、利用中止要請の方法</h3>
                  <h4>（１）以下の利用者情報については、その収集が行われる前にお客様の同意を得るものとします。</h4>
                  <ul>
                    <li>位置情報</li>
                  </ul>
                  <h4>（２）お客様は、利用者情報の全部または一部についてその収集または利用の停止を申し出ることができます。この場合、弊所はお客様本人からの申し出であることを確認し、速やかに弊所の定めるところに従い、その利用を停止します。ただし、法令により保管を義務付けられている情報は除きます。</h4>
                  
                </div>
                <div className="privacy__about__item">
                  <h3>４．外部送信、第三者提供、情報モジュールの有無</h3>
                  <h4>
                    （１）本サービスでは、以下の提携先が、お客様の端末にCookieを保存し、これを利用して利用者情報を蓄積及び利用している場合があります。
                  </h4>
                  <ul>
                    <li>提携先：Google</li>
                    <li>上記提携先のプライバシーポリシー：<a href='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>Googleプライバシーポリシー</a></li>
                  </ul>
                  <h4>
                    （２）本サービスには以下の情報モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者（日本国外にある者を含みます。）への利用者情報の提供を行います。
                  </h4>
                  <ul>
                    <li>情報収集モジュールの名称：Googleアナリティクス</li>
                    <li>情報収集モジュールの提供者：Google</li>
                    <li>上記情報収集モジュールの利用規約：<a href='https://marketingplatform.google.com/about/analytics/terms/jp' target='_blank' rel='noopener noreferrer'>Google アナリティクス利用規約</a></li>
                    <li>上記提供者における利用目的：<a href='https://policies.google.com/technologies/partner-sites?hl=ja' target='_blank' rel='noopener noreferrer'>Googleの利用目的</a></li>
                  </ul>
                </div>
                <div className="privacy__about__item">
                  <h3>５．第三者提供</h3>
                  <p>
                    弊所は、利用者情報のうち、個人情報については、あらかじめお客様の同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。ただし、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
                  </p>
                  <h4>（１）弊所が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</h4>
                  <h4>（２）合併その他の事由による事業の承継に伴って個人情報が提供される場合</h4>
                  <h4>（３）第4項の定めに従って、提供先または情報収集モジュール提供者への個人情報が提供される場合</h4>
                  <h4>（４）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合</h4>
                  <h4>（５）依頼された業務の内容が、国の機関もしくは地方公共団体またはその委託を受けた者に対して、法令により個人情報を提供することが定められている場合</h4>
                  <h4>（６）その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合</h4>
                </div>
                <div className="privacy__about__item">
                  <h3>６．共同利用</h3>
                  <p>
                    弊所は、以下のとおりお客様の個人情報を共同利用します。
                  </p>
                  <h4>（１）共同して利用される個人情報の項目</h4>
                  <p>上記１項（１）で取得した個人情報</p>
                  <h4>（２）共同して利用する者の範囲</h4>
                  <p>弊所に依頼された司法書士業務に関して、お客様が契約または契約することが確実と見込まれる契約の当事者、及び契約の当事者が契約または契約することが確実と見込まれる不動産会社、金融機関、弁護士、司法書士、土地家屋調査士、税理士、宅地建物取引士等の当該契約関係者。</p>
                  <h4>（３）共同して利用する者の利用目的</h4>
                  <p>上記２項（１）及び（２）を利用目的とします。</p>
                  <h4>（４）個人情報の管理について責任を有する者の住所、名称及び代表者</h4>
                  <p>
                    大阪市中央区備後町４丁目１番３号御堂筋三井ビル<br />
                    司法書士法人みつわ合同事務所　代表社員　髙瀬雅祥
                  </p>
                </div>
                <div className="privacy__about__item">
                  <h3>７．個人情報の開示</h3>
                  <p>
                    弊所は、お客様から、個人情報保護法の定めに基づき個人情報の開示を求められた時は、お客様ご本人からのご請求であることを、弊所が定める方法で確認した上で、お客様に対し、遅滞なく開示を行います（当該個人情報が存在しないときはその旨を通知いたします。）ただし、個人情報保護法その他の法令により、弊所が義務を負わない場合はこの限りではありません。なお、個人情報の開示につきましては、手数料（１件あたり1,000円）を先払いにて頂戴しておりますので、あらかじめご了承ください。
                  </p>
                </div>
                <div className="privacy__about__item">
                  <h3>８．個人情報の訂正及び利用停止等</h3>
                  <p>
                    （１）弊所は、お客様から、①個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び②あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、お客様ご本人からのご請求であることを、弊所の定める方法により確認した上で、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をお客様に通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、お客様に対しその旨を通知いたします。
                  </p>
                  <p>
                    （２）弊所は、お客様から、お客様の個人情報について消去を求められた場合、弊所が当該請求に応じる必要があると判断した場合は、お客様ご本人からのご請求であることを、弊所の定める方法により確認した上で、個人情報の消去を行い、その旨をお客様に通知いたします。ただし、法令等により、保管が義務付けられている個人情報についてはこの限りではありません。
                  </p>
                </div>
                <div className="privacy__about__item">
                  <h3>９．お問い合わせ窓口</h3>
                  <p>
                    ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。
                  </p>
                  <p>
                    住所：〒541−0051　大阪市中央区備後町４丁目１番３号 御堂筋三井ビル<br/>
	                  名称：司法書士法人みつわ合同事務所<br/>
                    個人情報取扱責任者：髙瀬雅祥<br/>
                    連絡先：info@mitsuwa-off.jp
                  </p>
                </div>
                <div className="privacy__about__item">
                  <h3>１０．個人情報保護方針の変更手続き</h3>
                  <p>
                    弊所は、必要に応じて、本ポリシーを変更します。ただし、法令上お客様の同意が必要となるような本ポリシーの変更を行う場合、変更後のポリシーは、弊所指定の方法で変更に同意したお客様に対してのみ適用されるものとします。なお、弊所は、本ポリシーを変更する場合には、変更後のポリシーの施行時期及び内容を弊所のウェブサイト上での表示その他適切な方法により周知し、またはお客様に通知します。
                  </p>
                </div>
                <div className="privacy__about__item">
                  <p>
                    【２０２１年５月１８日制定】
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="link__area">
          <Link to={"/contact/"}>
            <button className="link__btn">戻る</button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
